import { lazy, useEffect, useState } from 'react';

import LogoWordmark from '@m/assets/svg/logo-wordmark';
import { Box } from '@m/components/uikit';
import { Visit } from '@m/types/api';
import { selfParkVisitOpen, valetVisitOpen } from '@m/utils/StatusUtil';
import { useUnit } from 'effector-react';
import { parse } from 'query-string';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import {
  Header,
  ScrimBackground,
  StackedContent,
} from 'apps/customer/components/SharedForms/SharedForms.styled';
import { ContainerWithFooter } from 'apps/customer/components/StickyFooter/StickyFooter.styled';
import { HelpBox, SkipButton } from 'apps/customer/containers/Onboard/Onboard.styled';
import { LazyRoute } from 'apps/customer/routes/LazyRoute';
import { DefaultOnboardingRoutes, ProtectedRoutes } from 'apps/customer/routes/paths';
import { routeStore } from 'apps/customer/state/route';
import { todoStore } from 'apps/customer/state/todo';
import { userStore } from 'apps/customer/state/user';
import { visitStore } from 'apps/customer/state/visit';

const SignInPage = lazy(() => import('apps/customer/containers/Onboard/pages/SignInPage'));
const SignUpAddVehiclePage = lazy(
  () => import('apps/customer/containers/Onboard/pages/SignUpAddVehiclePage'),
);
const SignUpPage = lazy(() => import('apps/customer/containers/Onboard/pages/SignUpPage'));
const SignUpPaymentPage = lazy(
  () => import('apps/customer/containers/Onboard/pages/SignUpPaymentPage'),
);
const VerifyPage = lazy(() => import('apps/customer/containers/Onboard/pages/VerifyPage'));

const HELP_URL = 'https://metropolishelp.zendesk.com/hc/en-us';

export default function SignUpRoutes() {
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const { validation } = parse(search);
  // is this used anymore? do all validations go to /onboard?
  const [hasValidation] = useState<boolean>(!!validation);

  const { isInvitedUser, enterpriseUser } = useUnit(userStore);
  const { hasRequiredTasks } = useUnit(todoStore);
  const { visits } = useUnit(visitStore);
  const { signInLandingPage } = useUnit(routeStore);

  const [transaction, setTransaction] = useState<Visit | {}>(visits[0] || {});

  const isValetVisitOpen = valetVisitOpen(transaction as Visit);
  const isSelfParkOpen = selfParkVisitOpen(transaction as Visit);

  const isVisitOpen = Boolean(isValetVisitOpen || isSelfParkOpen);

  // Used to find open visits
  useEffect(() => {
    setTransaction(visits[0] || {});
  }, [visits]);

  /** Same logic as SignUpPaymentPage.onSuccess */
  const onSkipClick = () => {
    if (signInLandingPage) {
      history.push(signInLandingPage);
    } else if (enterpriseUser || !isInvitedUser || (hasRequiredTasks === false && !isVisitOpen)) {
      history.push(ProtectedRoutes.MembershipPage + location.search);
    } else {
      history.push(ProtectedRoutes.ForwardSlash + location.search);
    }
  };

  return (
    <LazyRoute>
      <Switch>
        <Box
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          backgroundColor="#fff"
          data-testid="SignUpContainer"
        >
          <ContainerWithFooter alignItems="center">
            <ScrimBackground>
              <StackedContent>
                <Header>
                  {/* Should we get rid of dimensions from uikit icons? */}
                  <LogoWordmark width={120} />
                  {location.pathname === DefaultOnboardingRoutes.PaymentMethod &&
                    isValetVisitOpen && (
                      <SkipButton type="button" onClick={onSkipClick} data-onboard="skip-payment">
                        Skip
                      </SkipButton>
                    )}
                </Header>
                <LazyRoute path={DefaultOnboardingRoutes.SignIn} exact>
                  <SignInPage hasValidation={hasValidation} />
                </LazyRoute>
                <LazyRoute path={DefaultOnboardingRoutes.Verify} exact>
                  <VerifyPage />
                </LazyRoute>
                <LazyRoute path={DefaultOnboardingRoutes.SignUp} exact>
                  <SignUpPage />
                </LazyRoute>
                <LazyRoute path={DefaultOnboardingRoutes.Vehicle} exact>
                  <SignUpAddVehiclePage />
                </LazyRoute>
                <LazyRoute path={DefaultOnboardingRoutes.PaymentMethod} exact>
                  <SignUpPaymentPage />
                </LazyRoute>
              </StackedContent>
            </ScrimBackground>
            {location.pathname === DefaultOnboardingRoutes.SignIn && (
              <HelpBox>
                <div>Need help getting started?</div>
                <a href={HELP_URL} target="_blank" rel="noreferrer">
                  Contact support
                </a>
              </HelpBox>
            )}
          </ContainerWithFooter>
        </Box>
      </Switch>
    </LazyRoute>
  );
}
