/**
 * Make API calls to fetch transactions data repeatedly.
 */

import { useEffect } from 'react';

import Configurator from '@m/config/Configurator';
import { fetchCustomerVisits } from 'apps/customer/state/visit';

let intervalId: any;

export default function useVisitPolling({
  interval = Configurator.DATA_REFRESH_INTERVAL,
  enabled = true,
}) {
  useEffect(() => {
    if (enabled) {
      intervalId = setInterval(() => {
        fetchCustomerVisits();
      }, interval);
    }

    return () => clearInterval(intervalId);
  }, [interval, enabled]);
}
