import {
  AUS,
  BOL,
  CHT,
  CLE,
  CLT,
  CHI,
  DAL,
  DC,
  DEN,
  DET,
  FW,
  HUS,
  KC,
  KNX,
  LA,
  MEM,
  MetropolisRegionName,
  MIA,
  MIN,
  NCAL,
  NSH,
  NYC,
  PIT,
  ROA,
  SA,
  SAC,
  SEA,
  SLC,
  SP,
  STL,
} from '@m/constants/Regions';
import { Site } from '@m/types/api';
import { CenterCoordinate } from '@m/types/MapTypes';
import getCoordinateDistance from '@m/utils/getCoordinateDistance';

export type MetropolisRegion = CenterCoordinate & { name: MetropolisRegionName };

const regionCoordinates: Record<MetropolisRegionName, MetropolisRegion> = {
  AUS: {
    name: AUS,
    latitude: 30.2698,
    longitude: -97.7305,
    zoom: 12.5,
  },
  BOL: {
    name: BOL,
    latitude: 40.0148,
    longitude: -105.2745,
    zoom: 12.5,
  },
  CHI: {
    name: CHI,
    latitude: 41.9695,
    longitude: -87.7919,
    zoom: 10.5,
  },
  CHT: {
    name: CHT,
    latitude: 35.0244,
    longitude: -85.3012,
    zoom: 11.5,
  },
  CLE: {
    name: CLE,
    latitude: 41.5074,
    longitude: -81.6872,
    zoom: 11.5,
  },
  CLT: {
    name: CLT,
    latitude: 35.2391,
    longitude: -80.8458,
    zoom: 11.5,
  },
  DAL: {
    name: DAL,
    latitude: 32.7884,
    longitude: -96.795,
    zoom: 11.5,
  },
  DC: {
    name: DC,
    latitude: 38.886,
    longitude: -77.0227,
    zoom: 11.5,
  },
  DEN: {
    name: DEN,
    latitude: 39.7523,
    longitude: -104.9856,
    zoom: 10.5,
  },
  DET: {
    name: DET,
    latitude: 42.3453,
    longitude: -83.0422,
    zoom: 13.5,
  },
  FW: {
    name: FW,
    latitude: 32.758,
    longitude: -97.3293,
    zoom: 14.2,
  },
  HUS: {
    name: HUS,
    latitude: 29.7698,
    longitude: -95.3573,
    zoom: 11.5,
  },
  KC: {
    name: KC,
    latitude: 39.1043,
    longitude: -94.5755,
    zoom: 11.5,
  },
  KNX: {
    name: KNX,
    latitude: 35.9751,
    longitude: -83.9126,
    zoom: 11.5,
  },
  LA: {
    name: LA,
    latitude: 34.015,
    longitude: -118.377,
    zoom: 9.75,
  },
  MEM: {
    name: MEM,
    latitude: 35.1328,
    longitude: -90.047,
    zoom: 11.5,
  },
  MIA: {
    name: MIA,
    latitude: 25.791,
    longitude: -80.225,
    zoom: 10.5,
  },
  MIN: {
    name: MIN,
    latitude: 44.9777,
    longitude: -93.265,
    zoom: 11.5,
  },
  NCAL: {
    name: NCAL,
    latitude: 37.927,
    longitude: -122.053,
    zoom: 9.5,
  },
  NSH: {
    name: NSH,
    latitude: 36.1579,
    longitude: -86.7763,
    zoom: 11.5,
  },
  NYC: {
    name: NYC,
    latitude: 40.7329,
    longitude: -74.0034,
    zoom: 10.5,
  },
  PIT: {
    name: PIT,
    latitude: 40.4489,
    longitude: -79.9555,
    zoom: 11.5,
  },
  ROA: {
    name: ROA,
    latitude: 37.2678,
    longitude: -79.9401,
    zoom: 13.5,
  },
  SA: {
    name: SA,
    latitude: 29.4502,
    longitude: -98.4803,
    zoom: 10.5,
  },
  SAC: {
    name: SAC,
    latitude: 38.5946,
    longitude: -121.4986,
    zoom: 10.5,
  },
  SEA: {
    name: SEA,
    latitude: 47.605,
    longitude: -122.277,
    zoom: 10.5,
  },
  SLC: {
    name: SLC,
    latitude: 40.7762,
    longitude: -111.8742,
    zoom: 12.5,
  },
  SP: {
    name: SP,
    latitude: 44.9476,
    longitude: -93.0857,
    zoom: 11.5,
  },
  STL: {
    name: STL,
    latitude: 38.6323,
    longitude: -90.1933,
    zoom: 11.5,
  },
};

export default regionCoordinates;

export const DEFAULT_REGION = regionCoordinates.DET;

export const getClosestMetropolisRegion = ({
  latitude,
  longitude,
}: {
  latitude?: number;
  longitude?: number;
} = {}) => {
  if (!latitude || !longitude) {
    return DEFAULT_REGION;
  }
  const currCoord = [longitude, latitude];
  let closestRegion = DEFAULT_REGION.name;
  let minDist: null | number = null;
  (Object.keys(regionCoordinates) as MetropolisRegionName[]).forEach((region) => {
    const distance = getCoordinateDistance(
      [regionCoordinates[region].longitude, regionCoordinates[region].latitude],
      currCoord,
    );
    if (minDist === null || distance < minDist) {
      minDist = distance;
      closestRegion = region;
    }
  });

  return regionCoordinates[closestRegion] || DEFAULT_REGION;
};

// Should prioritize site location instead of IP location
export function getRegionToDisplay({
  // Should we directly retrieve the region from the geolocationStore
  // instead of passing the "closestRegion"?
  regionName,
  site,
}: {
  regionName?: MetropolisRegionName | string;
  site?: Site;
}): MetropolisRegion {
  if (site) {
    if (site.lat && site.lng) {
      return getClosestMetropolisRegion({
        latitude: site.lat,
        longitude: site.lng,
      });
    }
  }

  return (regionName && regionCoordinates[regionName as MetropolisRegionName]) || DEFAULT_REGION;
}

export function isRegionValid(region: MetropolisRegionName | string) {
  return !!regionCoordinates[region as MetropolisRegionName];
}
