import { applyReducers, DoneHandler } from '@m/effector-utils';
import { APIResponse } from '@m/utils/http';
import { getRegionToDisplay, MetropolisRegion } from '@m/utils/regionCoordinates';
import { createStore, Effect, Event } from 'effector';

import { getGeolocationIP, setClosestRegion } from './actions';

import { MetropolisRegionName } from 'constants/Regions';

export type GeolocationState = {
  closestRegion?: MetropolisRegion; // Region that we display. Where sites are located
  region?: string; // User state/region based on IP address
  latitude?: number; // Based on IP address
  longitude?: number; // Based on IP address
};

const initialState: GeolocationState = {};

const store = createStore(initialState);

type Reducers = {
  getGeolocationIP: {
    action: Effect<Parameters<typeof getGeolocationIP>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getGeolocationIP>[0], GeolocationState>;
  };
  setClosestRegion: {
    action: Event<MetropolisRegionName>;
    reducer: (state: GeolocationState, payload: MetropolisRegionName) => GeolocationState;
  };
};

export const reducers: Reducers = {
  getGeolocationIP: {
    action: getGeolocationIP,
    done: (state, { result: { success, data } }) => {
      if (success && data) {
        const { region, lon: longitude, lat: latitude, closestRegion } = data;
        return {
          ...state,
          region,
          longitude,
          latitude,
          closestRegion,
        };
      }
      return state;
    },
  },
  setClosestRegion: {
    action: setClosestRegion,
    reducer: (state, region) => {
      const closestRegion = getRegionToDisplay({ regionName: region });
      return {
        ...state,
        closestRegion,
      };
    },
  },
};

export default applyReducers({ store, reducers });
