import * as React from 'react';
import type { SVGProps } from 'react';

const SvgExternalWindow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#5E59F6"
      d="M15 16H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H2a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1M11 1c0 .55.45 1 1 1h2.59l-9.13 9.13a.996.996 0 1 0 1.41 1.41L16 3.41V6c0 .55.45 1 1 1s1-.45 1-1V1c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1"
    />
  </svg>
);
export default SvgExternalWindow;
