/**
 * Creates a container to allow sticky footers
 *
 * Usage:
 * <ContainerWithFooter>
 *   <ExpandedContent>
 *     ...
 *   </ExpandedContent>
 *   <Footer />
 * </ContainerWithFooter
 */
import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const ContainerWithFooter = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${withStyledSystem}
`;

export const ExpandedContent = styled.div<StyledSystemProps>`
  flex: 1;

  ${withStyledSystem}
`;
