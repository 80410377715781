import { useEffect } from 'react';

import { getGeolocationIP } from 'apps/customer/state/geolocation';

export function useClosestMetropolisRegion() {
  /* Handle region to display */
  useEffect(() => {
    getGeolocationIP();
  }, []);
}
