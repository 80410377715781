import { getClosestMetropolisRegion, MetropolisRegion } from '@m/utils/regionCoordinates';
import { add } from 'date-fns';
import { createEffect, createEvent } from 'effector';
import lStorage from 'local-storage-fallback';

import type { APIResponse } from '@m/utils/http';

import { MetropolisRegionName } from 'constants/Regions';

const GeoLocationApi = 'https://services.metropolis.io/where-am-i';

export const setClosestRegion = createEvent<MetropolisRegionName>();

export const getGeolocationIP = createEffect({
  handler: async () => {
    try {
      const response = await fetch(GeoLocationApi);
      const responseData: {
        lat?: string;
        long?: string;
        city?: string;
        closestRegion?: MetropolisRegion;
      } = await response.json();
      if (!responseData.lat || !responseData.long) throw new Error('No lat or long');
      const { long: longitude, lat: latitude } = responseData;
      const expire = add(new Date(), { months: 1 }).getTime();
      const closestRegion = getClosestMetropolisRegion({
        longitude: Number(longitude),
        latitude: Number(latitude),
      });
      lStorage.setItem('mp-region', JSON.stringify({ region: closestRegion.name, expire }));

      responseData.closestRegion = closestRegion;
      const responseObject: APIResponse = {
        success: true,
        data: responseData,
      };
      return responseObject;
    } catch (e) {
      return {
        success: true,
        data: {
          closestRegion: getClosestMetropolisRegion(),
        },
      };
    }
  },
});
